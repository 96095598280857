import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import CorporateSOSidebar from "../CorporateSOSidebar/CorporateSOSidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../StatusBandCorporateSO/CorporateSOStatusBand.css";
import { Toast } from "primereact/toast";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import TranslationFile from "../../Translation/Translation.json";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let loginDetails = "";
let userSelectedLanguage;

class StatusBandCorporateSO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked2: "false",
      selectedStatusCorp: "",
      corpEn: "",
      corpSw: "",
      sideBarStatus: true,
      corpEnValueOnChange: false,
      showCorpStatusBand: false,
      statusColor: "",
    };
    this.options = ["Off", "On"];

    this.statusType = [
      { name: "Success", code: "S", color: "green" },
      { name: "Info", code: "I", color: "blue" },
      { name: "Error", code: "E", color: "red" },
    ];

    this.selectStatus = this.selectStatus.bind(this);
    this.UpdateStatusBand = this.UpdateStatusBand.bind(this);
  }

  selectStatus(e) {
    this.setState({ selectedStatusCorp: e.target.value });
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showCorpStatusBand: true });

      axios.get("/siteOwner/getStatusBand?appId=0").then((response) => {
        let respData = response.data;
        this.setState({
          checked2: respData.activate,
          corpEn: respData.statusTextEn,
          corpSw: respData.statusTextSw,
          statusColor: respData.statusColor,
        });

        if (respData.statusType == 0) {
          this.setState({
            selectedStatusCorp: this.statusType[0],
          });
        } else if (respData.statusType == 1) {
          this.setState({
            selectedStatusCorp: this.statusType[1],
          });
        } else {
          this.setState({
            selectedStatusCorp: this.statusType[2],
          });
        }
      });
    } else {
      this.setState({ showCorpStatusBand: false });
      this.props.history.push("/siteOwner");
    }
  }

  UpdateStatusBand(portal) {
    let { selectedStatusCorp } = this.state;
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      let portalUpdate = "",
        statusType;
      if (portal == "corps") {
        if (selectedStatusCorp.name == "Success") {
          statusType = 0;
        } else if (selectedStatusCorp.name == "Info") {
          statusType = 1;
        } else statusType = 2;

        portalUpdate = {
          appId: 0,
          statusTextEn: this.state.corpEn,
          statusTextSw: this.state.corpSw,
          statusType: statusType,
          activate: this.state.checked2,
          statusColor: "#" + this.state.statusColor,
        };
      }

      axios
        .post(
          "/siteOwner/updateSiteOwnerStatusBand",
          portalUpdate,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data == "Success") {
            this.toast.show({
              severity: "success",
              summary: "success",
              life: 3000,
            });
          }
          this.setState({ corpEnValueOnChange: false });
        });
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  checked2OnSwitch(e) {
    this.setState({ checked2: e.value, corpEnValueOnChange: true });
  }
  corpEnOnChange(e) {
    this.setState({ corpEn: e.target.value, corpEnValueOnChange: true });
  }
  corpSwOnChange(e) {
    this.setState({ corpSw: e.target.value, corpEnValueOnChange: true });
  }
  corpStatusType(e) {
    this.setState({
      selectedStatusCorp: e.target.value,
      corpEnValueOnChange: true,
    });
  }

  render() {
    const { selectedStatusCorp, sideBarStatus, statusColor } = this.state;
    const header = (
      <div className="statusBand-Header-Tab">
        {TranslationFile.Statusband[userSelectedLanguage]}
        {/* Status Band */}
      </div>
    );
    return (
      <div className="StatusBand-Main-Div">
        {this.state.showCorpStatusBand && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="StatusBand_Sidebar">
              <CorporateSOSidebar
                children={"StatusBand"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <Toast
              ref={(el) => {
                this.toast = el;
              }}
              position="center"
              // className="wrongSIEFileUpload"
            ></Toast>

            <div
              className={
                sideBarStatus
                  ? "CorpStatusBand-DataTable"
                  : "CorpStatusBand-DataTableClose"
              }
            >
              <div className="statusBand-Header-Title"> {header} </div>
              <TabView className="StatusBand-set-up-tab">
                <TabPanel header="Corp Portal Status Band">
                  <div className="statusAlert-Main-Div">
                    <div>
                      <Row style={{ padding: "5px 20px" }}>
                        <Col xs={5}>
                          <b className="StatusBand_table2">
                            {TranslationFile.Activate[userSelectedLanguage]}
                            {/* Activate */}
                          </b>{" "}
                          <InputSwitch
                            checked={this.state.checked2}
                            onChange={(e) => this.checked2OnSwitch(e)}
                          />
                          <br></br>
                          <br></br>
                          <Row>
                            <Col xs={5}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusTextEnglish[
                                    userSelectedLanguage
                                  ]
                                }
                              </b>
                              {/* <b>Status Text English</b> */}
                              <InputTextarea
                                autoResize
                                value={this.state.corpEn}
                                onChange={(e) => this.corpEnOnChange(e)}
                                rows={2}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={5}>
                              <b>
                                {
                                  TranslationFile.StatusTextSwedish[
                                    userSelectedLanguage
                                  ]
                                }
                              </b>
                              <InputTextarea
                                autoResize
                                value={this.state.corpSw}
                                onChange={(e) => this.corpSwOnChange(e)}
                                rows={2}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "12px" }}>
                            <Col xs={4}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusType[
                                    userSelectedLanguage
                                  ]
                                }
                              </b>
                              <br></br>
                              <Dropdown
                                value={selectedStatusCorp}
                                onChange={(e) => this.selectStatus(e)}
                                options={this.statusType}
                                optionLabel="name"
                                placeholder="select..."
                                className="corpStatusdropdown"
                                // itemTemplate={this.colorOptionTemplate}
                                // valueTemplate={this.selectedColorTemplate}
                              />
                            </Col>
                            <Col xs={5}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusColor[
                                    userSelectedLanguage
                                  ]
                                }
                              </b>
                              <br></br>
                              <ColorPicker
                                value={this.state.statusColor}
                                onChange={(e) =>
                                  this.setState({
                                    statusColor: e.value,
                                    corpEnValueOnChange: true,
                                  })
                                }
                              />
                              <InputText
                                value={this.state.statusColor}
                                onChange={(e) =>
                                  this.setState({
                                    statusColor: e.target.value,
                                    corpEnValueOnChange: true,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col>
                              <Button
                                onClick={() => this.UpdateStatusBand("corps")}
                                disabled={
                                  !this.state.corpEnValueOnChange && true
                                }
                              >
                                {TranslationFile.SAVE[userSelectedLanguage]}
                                {/* SAVE */}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default StatusBandCorporateSO;
