import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assests/RebelSkool.png";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import svFlag from "../../Assests/sweden_flag.webp";
import enFlag from "../../Assests/USUK_Flag.jpg";
import "../HeaderNavBar/HeaderNavBar.css";
import { HashLink } from "react-router-hash-link";
import TranslationFile from "../Translation/Translation.json";

let userSelectedLanguage;

class HeaderNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showName: false,
      userNameFromLS: "",
    };
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    let ownerFromLs = localStorage.getItem("RBLSiteOwner");
    ownerFromLs = JSON.parse(ownerFromLs);
    if (ownerFromLs && ownerFromLs.response == "True") {
      this.setState({
        showName: true,
      });
    }

    if (ownerFromLs != null && ownerFromLs["userName"] !== undefined) {
      this.setState({ userNameFromLS: ownerFromLs.userName });
    }
  }

  flagOnClick(langCode) {
    if (langCode == "sw") {
      localStorage.setItem("selectedLanguage", "sw");
    } else {
      localStorage.setItem("selectedLanguage", "en");
    }
    window.location.reload(false);
  }

  signOut(e) {
    e.preventDefault();
    localStorage.setItem("RBLSiteOwner", null);
    window.location.href = "/siteOwner";
  }

  render() {
    const { userNameFromLS } = this.state;

    return (
      <div className="corp-body">
        <div className="over-menu"></div>
        <div id="wrap">
          <header className="header" id="header-sroll">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="desk-menu">
                    <nav className="box-menu">
                      <div className="logo-corp">
                        <h1 className="logo-adn">
                          <a>
                            <Image
                              src={logo}
                              alt="Image"
                              className="corp-navbar-logo"
                            />
                          </a>
                        </h1>
                      </div>

                      <div className="menu-container">
                        <div className={"menu-header-container"}>
                          <ul id="cd-primary-nav" className="menu">
                            <li className="menu-item menu-item-has-children">
                              <div className="corp-div-image-logo">
                                <Image
                                  src={logo}
                                  alt="Image"
                                  className="corp-navbar-logo"
                                />
                              </div>
                            </li>

                            <li className="menu-item menu-item-has-children">
                              <a href="">
                                {
                                  TranslationFile.SelectPortal[
                                    userSelectedLanguage
                                  ]
                                }
                                {/* Select Portal */}
                                <span className="arrow"></span>
                              </a>

                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <span>
                                    <HashLink to="/siteOwner/corporatePortal/CorporateHomePage">
                                      {
                                        TranslationFile.CorporatePortal[
                                          userSelectedLanguage
                                        ]
                                      }
                                      {/* Corporate Portal */}
                                    </HashLink>
                                  </span>
                                </li>
                                <li className="menu-item">
                                  <span>
                                    <HashLink to="/siteOwner/appsPortal">
                                      {
                                        TranslationFile.AppsPortal[
                                          userSelectedLanguage
                                        ]
                                      }
                                      {/* Apps Portal */}
                                    </HashLink>
                                  </span>
                                </li>
                                <li className="menu-item">
                                  <span>
                                    <HashLink to="/siteOwner/logs">
                                      {
                                        TranslationFile.SiteLogs[
                                          userSelectedLanguage
                                        ]
                                      }
                                      {/* Site Logs */}
                                    </HashLink>
                                  </span>
                                </li>
                              </ul>
                            </li>

                            <li className="menu-item menu-item-has-children">
                              {userSelectedLanguage == "sw" ? (
                                <a href="" className="Nav_LangID">
                                  <Image
                                    src={svFlag}
                                    width="40"
                                    height="25"
                                    className="lang-menu-flag"
                                  ></Image>
                                  <span className="arrow"></span>
                                </a>
                              ) : (
                                <a href="" className="Nav_LangID">
                                  <Image
                                    src={enFlag}
                                    width="40"
                                    height="25"
                                    className="lang-menu-flag"
                                  ></Image>
                                  <span className="arrow"></span>
                                </a>
                              )}

                              <ul className="sub-menu" id="lang-flag-ul">
                                <li className="menu-item">
                                  <Image
                                    src={svFlag}
                                    width="40"
                                    height="25"
                                    onClick={() => this.flagOnClick("sw")}
                                  ></Image>
                                </li>
                                <li className="menu-item">
                                  <Image
                                    src={enFlag}
                                    width="40"
                                    height="25"
                                    onClick={() => this.flagOnClick("en")}
                                  ></Image>
                                </li>
                              </ul>
                            </li>

                            {this.state.showName ? (
                              <li className="menu-item menu-item-has-children">
                                <a href="">
                                  {TranslationFile.Hi[userSelectedLanguage]}
                                  {userNameFromLS}
                                  <span className="arrow"></span>
                                </a>
                                <ul className="sub-menu">
                                  <li className="menu-item">
                                    <a onClick={(e) => this.signOut(e)}>
                                      {
                                        TranslationFile.SignOut[
                                          userSelectedLanguage
                                        ]
                                      }
                                      {/* Sign Out */}
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <li className="menu-item menu-item-has-children">
                                <div className="corp-login">
                                  <Button value="Login" className="login-btn">
                                    {
                                      TranslationFile.login[
                                        userSelectedLanguage
                                      ]
                                    }
                                    {/* login */}
                                  </Button>
                                </div>
                              </li>
                            )}
                            <li className="line"></li>
                          </ul>
                        </div>
                      </div>
                      <div className="hamburger-menu">
                        <div className="bar"></div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}
export default HeaderNavBar;
