import React, { useState, useEffect } from "react";
import TranslationFile from "../../Translation/Translation.json";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FcInspection,
  FcMoneyTransfer,
  FcStatistics,
  FcOpenedFolder,
  FcKindle,
  FcDocument,
  FcAutomatic,
} from "react-icons/fc";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import "../SidebarAnnualReportSiteOwner/Sidebar.css";

let userSelectedLanguage;

const Sidebar = ({ children, sideBarStatus, switchValue }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedSideMenu, setSelectedSideMenu] = useState("");
  const activeSideBarReducer = useSelector(
    (state) => state.activeSideBar.activeSideBar.values
  );
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const SiteOwner = "/siteOwner/appsPortal";
  const Payment = SiteOwner + "/Payment";
  const Template = SiteOwner + "/Template";
  const EmailQueue = SiteOwner + "/EmailQueue";
  const AnnualReport = SiteOwner + "/AnnualReport";
  const SRU = SiteOwner + "/SRU";
  const PrivateUsers = SiteOwner + "/PrivateUsers";
  const AccountingCompany = SiteOwner + "/AccountingCompany";
  const StatusBand = SiteOwner + "/StatusBand";

  const routes = [
    {
      swName: "Min instrumentpanel",
      name: "Home Page",
      icon: <FcOpenedFolder className="icon" />,
      path: SiteOwner,
    },
    {
      swName: "Min instrumentpanel",
      name: "Payment",
      icon: <FcMoneyTransfer className="icon" />,
      path: Payment,
    },

    {
      swName: "Anteckningar",
      name: "Email Queue",
      icon: <FcInspection className="icon" />,
      path: EmailQueue,
    },
    {
      swName: "Annual Report",
      name: "Annual Report",
      icon: <FcKindle className="icon" />,
      path: AnnualReport,
    },
    {
      swName: "SRU",
      name: "SRU",
      icon: <FcKindle className="icon" />,
      path: SRU,
    },
    {
      swName: "Min instrumentpanel",
      name: "Templates",
      icon: <FcDocument className="icon" />,
      path: Template,
    },
    {
      swName: "Privata användare",
      name: "Private Users",
      icon: <FcKindle className="icon" />,
      path: PrivateUsers,
    },
    {
      swName: "Balansräkning",
      name: "Accounting Company",
      icon: <FcStatistics className="icon" />,
      path: AccountingCompany,
    },

    {
      swName: "Statusband",
      name: "Status Band",
      icon: <FcAutomatic className="icon" />,
      path: StatusBand,
    },
  ];

  useEffect(() => {
    if (activeSideBarReducer != undefined)
      setSelectedSideMenu(activeSideBarReducer[0]);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
    sideBarStatus(!isOpen);
  };

  return (
    <div>
      <div
        className={
          isOpen
            ? "main-container-sidebarOpenClass"
            : "main-container-sidebarCloseClass"
        }
      >
        <motion.div
          animate={{
            width: isOpen ? "250px" : "50px",
          }}
          className={switchValue ? "sidebarExtent" : `sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <p className="subtext-annual-report">
                  {/* Apps Portal */}
                  {TranslationFile.AppsPortal[userSelectedLanguage]}
                </p>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen == true ? (
                <RxDoubleArrowLeft className="icon" onClick={toggle} />
              ) : (
                <RxDoubleArrowRight className="icon" onClick={toggle} />
              )}
            </div>
          </div>

          <section className="routes">
            {/* Home Page */}
            <NavLink
              to={routes[0].path}
              className={children == "HomePage" ? "sidebarActive" : "notesLink"}
              activeClassName="active"
            >
              <div className="icon">{routes[0].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[0].swName
                      : routes[0].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* Payment */}
            <NavLink
              to={routes[1].path}
              className={children == "Payment" ? "sidebarActive" : "notesLink"}
              activeClassName="active"
            >
              <div className="icon">{routes[1].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[1].swName
                      : routes[1].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* EmailQueue */}
            <NavLink
              to={routes[2].path}
              className={
                children == "EmailQueue" ? "sidebarActive" : "notesLink"
              }
              activeClassName="active"
            >
              <div className="icon">{routes[2].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[2].swName
                      : routes[2].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* AnnualReport */}
            <NavLink
              to={routes[3].path}
              className={
                children == "AnnualReport" ? "sidebarActive" : "notesLink"
              }
              activeClassName="active"
            >
              <div className="icon">{routes[3].icon} </div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[3].swName
                      : routes[3].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* SRU */}
            <NavLink
              to={routes[4].path}
              className={children == "SRU" ? "sidebarActive" : "notesLink"}
              activeClassName="active"
            >
              <div className="icon">{routes[4].icon} </div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[4].swName
                      : routes[4].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* Templates */}
            <NavLink
              to={routes[5].path}
              className={children == "Template" ? "sidebarActive" : "notesLink"}
              activeClassName="active"
            >
              <div className="icon">{routes[5].icon}</div>

              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[5].swName
                      : routes[5].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* PrivateUsers */}
            <NavLink
              to={routes[6].path}
              className={
                children == "PrivateUsers" ? "sidebarActive" : "notesLink"
              }
              activeClassName="active"
            >
              <div className="icon">{routes[6].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[6].swName
                      : routes[6].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/* Accouting Company */}
            <NavLink
              to={routes[7].path}
              className={
                children == "AccountingCompany" ? "sidebarActive" : "notesLink"
              }
              activeClassName="active"
            >
              <div className="icon">{routes[7].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[7].swName
                      : routes[7].name}
                  </div>
                )}
              </div>
            </NavLink>

            {/*StatusBand */}
            <NavLink
              to={routes[8].path}
              className={
                children == "StatusBand" ? "sidebarActive" : "notesLink"
              }
              activeClassName="active"
            >
              <div className="icon">{routes[8].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[8].swName
                      : routes[8].name}
                  </div>
                )}
              </div>
            </NavLink>
          </section>
        </motion.div>
      </div>
    </div>
  );
};
export default Sidebar;
