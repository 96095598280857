import React, { Component } from "react";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import axios from "axios";
import "../EmailQueueSiteOwner/EmailQueue.css";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import TranslationFile from "../../Translation/Translation.json";
import { Toast } from "primereact/toast";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let loginDetails = "";
let userSelectedLanguage;
class EmailQueue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      globalFilter: null,
      emailQueueData: "",
      sideBarStatus: true,
      sendBtnLoading: false,
      currentEntryNo: "",
      showEmailQueuePage: false,
    };
    this.emailSendAction = this.emailSendAction.bind(this);
    this.sendMailBtnBodyTemplate = this.sendMailBtnBodyTemplate.bind(this);
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showEmailQueuePage: true });

      userSessionAuthKeyReturnValue = setUserSessionAuthKey();
      if (userSessionAuthKeyReturnValue) {
        axios
          .get(
            "/siteOwner/getEmailQueueForSiteOwner",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respData = response.data;
            this.setState({
              emailQueueData: respData,
            });
          });
      }
    } else {
      this.setState({ showEmailQueuePage: false });
      this.props.history.push("/siteOwner");
    }
  }

  acceptToSendMail(rowData) {
    this.setState({
      sendBtnLoading: true,
      currentEntryNo: rowData.entryNo,
    });
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/emailQueueController/sendMail?EmailQueueEntryNo=" + rowData.entryNo
        )
        .then((response) => {
          this.setState({ sendBtnLoading: false });
          this.toast.show({
            severity: "success",
            summary: "success",
            life: 3000,
          });
        })
        .catch((error) => {
          this.setState({ sendBtnLoading: false });
          this.toast.show({
            severity: "error",
            summary: error.response.status,
            detail:
              error.response.statusText + "." + " Contact info@rebelskool.com",
            life: 3000,
          });
        });
    }
  }

  emailSendAction(e, rowData) {
    confirmPopup({
      target: e.currentTarget,
      message: "Confirm Send Email to " + rowData.recipient,
      icon: "pi pi-exclamation-triangle",
      accept: this.acceptToSendMail.bind(this, rowData),
    });
  }

  sendMailBtnBodyTemplate(rowData) {
    if (this.state.currentEntryNo == rowData.entryNo) {
      return (
        <Button
          className="EmailQueue_SendMailBtn"
          onClick={(e) => this.emailSendAction(e, rowData)}
          icon={
            this.state.sendBtnLoading ? "pi pi-spin pi-spinner" : "pi pi-send"
          }
        ></Button>
      );
    } else {
      return (
        <Button
          className="EmailQueue_SendMailBtn"
          onClick={(e) => this.emailSendAction(e, rowData)}
          icon="pi pi-send"
        ></Button>
      );
    }
  }

  onRowEditComplete = (e) => {
    let { newData } = e;
    if (userSessionAuthKeyReturnValue) {
      let updateRecipient = {
        entryNo: newData.entryNo,
        recipient: newData.recipient,
      };

      axios
        .post(
          "/siteOwner/updateEmailQueue",
          updateRecipient,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data == "Success") {
            axios
              .get(
                "/siteOwner/getEmailQueueForSiteOwner",
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let respData = response.data;
                this.setState({
                  emailQueueData: respData,
                });
              });
          }
        });
    }
  };

  recipientEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  render() {
    const { sideBarStatus } = this.state;
    const header = (
      <div className="EmailQueue-Header-Tab">
        {TranslationFile.EmailQueue[userSelectedLanguage]}
        <span className="p-input-icon-left">
          {/* <i className="pi pi-search" /> */}
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder={TranslationFile.GlobalSearch[userSelectedLanguage]}
          />
        </span>
      </div>
    );
    return (
      <div className="EmailQueue-Main-Div">
        {this.state.showEmailQueuePage && (
          <div>
            <div className="EmailQueue_HeaderNavBar">
              <HeaderNavBar />
            </div>
            <div className="EmailQueue_Sidebar">
              <Sidebar
                children="EmailQueue"
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <ConfirmPopup />
            <Toast
              ref={(el) => {
                this.toast = el;
              }}
              className="companyInfoErrorToast"
            ></Toast>

            <div
              className={
                sideBarStatus
                  ? "EmailQueue-DataTable"
                  : "EmailQueue-DataTableClose"
              }
            >
              <div className="EmailQueue-Header-Title"> {header} </div>
              <DataTable
                value={this.state.emailQueueData}
                dataKey="id"
                globalFilter={this.state.globalFilter}
                emptyMessage="No Records found."
                editMode="row"
                onRowEditComplete={(e) => this.onRowEditComplete(e)}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
              >
                <Column
                  field="entryNo"
                  header={TranslationFile.EntryNo[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="emailType"
                  header={TranslationFile.EmailType[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="userName"
                  header={TranslationFile.UserName[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="recipient"
                  header={TranslationFile.Recipient[userSelectedLanguage]}
                  filter
                  editor={(options) => this.recipientEditor(options)}
                ></Column>
                <Column
                  field="contactMail"
                  header={TranslationFile.ContactMail[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="status"
                  header={TranslationFile.Status[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="noOfAttempts"
                  header={TranslationFile.NoOfAttempts[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="contactNumber"
                  header={TranslationFile.ContactNumber[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="createdDateTime"
                  header={TranslationFile.CreatedDateTime[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  field="lastProcessDateTime"
                  header={
                    TranslationFile.LastProcessDateTime[userSelectedLanguage]
                  }
                  filter
                ></Column>
                <Column
                  field="sentDateTime"
                  header={TranslationFile.SentDateTime[userSelectedLanguage]}
                  filter
                ></Column>
                <Column
                  rowEditor
                  headerStyle={{ width: "1%", minWidth: "2rem" }}
                  bodyStyle={{ textAlign: "left" }}
                  frozen
                  // alignFrozen="right"
                  className="EmailQueue_edit_frozen"
                ></Column>
                <Column
                  header={TranslationFile.Action[userSelectedLanguage]}
                  body={this.sendMailBtnBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default EmailQueue;
