import { connect } from "react-redux";
import React, { Component } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import logo from "../../Assests/RebelSkool.png";
import axios from "axios";
import "./SiteOwnerLoginPage.css";

let userSelectedLanguage;

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails.sessionDetails.values,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    payment: state.payment.payment,
  };
};
class SiteOwner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailOwner: "",
      passwordOwner: "",
      loginSucess: false,
      showAddSOUser: false,
    };
  }

  loginButtonSiteowner() {
    let siteOwnerUserDetails = {
      email: this.state.emailOwner,
      password: this.state.passwordOwner,
    };

    axios
      .post("/siteOwner/loginSiteOwnerUsersDetails", siteOwnerUserDetails)
      .then((response) => {
        let respData = response.data;
        if (respData != "") {
          respData["User-Session-Auth-Key"] =
            response.headers["user-session-auth-key"];
          if (response.data.response == "True") {
            localStorage.setItem("RBLSiteOwner", JSON.stringify(respData));
            this.setState({
              loginSucess: true,
            });
          } else {
            this.toast.show({
              severity: "error",
              summary: "Error",
              detail: "Invalid Password",
              life: 2000,
            });
          }
        } else {
          this.toast.show({
            severity: "error",
            summary: "Error",
            detail: "Invalid Password",
            life: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error.message, "@@@@@");
        this.toast.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid Password",
          life: 2000,
        });
      });
  }

  siteOwnerAnnualReportPage(field) {
    if (field == "annualReport") {
      this.props.history.push("/siteOwner/appsPortal");
    } else if (field == "addSOUser") {
      this.setState({ showAddSOUser: true });
    }
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    let loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = loginDetails && JSON.parse(loginDetails);

    if (loginDetails != null) {
      this.setState({
        loginSucess: true,
      });
    } else {
      this.setState({
        loginSucess: false,
      });
    }
  }

  refresh() {
    setTimeout(() => {
      window.location.reload(false);
    }, 10);
  }

  render() {
    const { emailOwner, passwordOwner } = this.state;
    return (
      <div className="siteOwner-Main-Div">
        <Toast ref={(el) => (this.toast = el)} />

        {this.state.loginSucess == false ? (
          <div className="siteOwner-LoginPage">
            <center>
              <div className="siteowner-RBL-Image">
                <Image
                  src={logo}
                  alt="Image"
                  width="250"
                  className="navbar-logo"
                  onClick={() => this.refresh()}
                />
              </div>
            </center>
            <div className="siteOwner-Login-Div">
              <div className="siteOwner-Login-Inside-Div">
                <h4>SiteOwner Login</h4>
                <div style={{ paddingTop: "20px" }}>
                  <div>
                    <div style={{ textAlign: "left" }}>Email ID</div>
                    <InputText
                      className="siteOwner_EmailTextBox"
                      value={this.state.emailOwner}
                      onChange={(e) =>
                        this.setState({ emailOwner: e.target.value })
                      }
                      placeholder="E-mail Address"
                    />
                  </div>
                  <br></br>

                  <div>
                    <div style={{ textAlign: "left" }}>Password</div>
                    <Password
                      className="siteOwner-EmailTextBox"
                      value={this.state.passwordOwner}
                      onChange={(e) =>
                        this.setState({ passwordOwner: e.target.value })
                      }
                      placeholder="Password"
                      feedback={false}
                      toggleMask
                    />
                  </div>
                </div>
                <br></br>

                <div style={{ textAlign: "center" }}>
                  {emailOwner != "" &&
                  emailOwner != null &&
                  passwordOwner != "" &&
                  passwordOwner != null ? (
                    <Button onClick={() => this.loginButtonSiteowner()}>
                      Login
                    </Button>
                  ) : (
                    <Button disabled>Login</Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          this.props.history.push("/siteOwner/appsPortal")
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(SiteOwner);
