import React, { Component } from "react";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import "./SRU.css";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import TranslationFile from "../../Translation/Translation.json";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let loginDetails = "";
let userSelectedLanguage;
class SRU extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userTableValues: "",
      globalFilter: null,
      loading: false,
      sideBarStatus: true,
      showAnnualReportSiteOwner: false,
      headerAccess: "",
      showAccessDialogBox: false,
      showFilterBox: false,
      companyName: "",
      registrationNo: "",
      postalCode: "",
      city: "",
      fromDate: "",
      toDate: "",
    };

    this.AnnualReportId = this.AnnualReportId.bind(this);
    this.getAnnualReportID = this.getAnnualReportID.bind(this);
  }

  getAnnualReportID(reportID) {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/siteOwner/getAnnualReportAccessStatusByReportID?reportID=" +
            reportID,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let respData = response.data;

          this.setState({
            headerAccess: respData,
            showAccessDialogBox: true,
          });
        });
    }
  }

  AnnualReportId(rowData) {
    return (
      <div
        style={{
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={() => this.getAnnualReportID(rowData.reportID)}
      >
        {rowData.reportID}
      </div>
    );
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showAnnualReportSiteOwner: true, showFilterBox: true });
    } else {
      this.setState({ showAnnualReportSiteOwner: false });
      this.props.history.push("/siteOwner");
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  filterAnnualReportRecords() {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      let filterDetails = {
        companyName:
          this.state.companyName.length > 0 ? this.state.companyName : null,
        registrationNo:
          this.state.registrationNo.length > 0
            ? this.state.registrationNo
            : null,
        postalCode:
          this.state.postalCode.length > 0 ? this.state.postalCode : null,
        city: this.state.city.length > 0 ? this.state.city : null,
        startDate: this.state.fromDate.length > 0 ? this.state.fromDate : null,
        endDate: this.state.toDate.length > 0 ? this.state.toDate : null,
        tableName: 'RBLSRUHeader'
      };

      axios
        .post(
          "/siteOwner/getAnnualReportHeaderWithFilters",
          filterDetails,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data.length > 0) {
            let respData = response.data;
            respData.map((i) => {
              const parsedDate = new Date(i.createdAtDateTime);
              const year = parsedDate.getFullYear();
              const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
              const day = String(parsedDate.getDate()).padStart(2, "0");
              const formattedDate = `${year}-${month}-${day}`;
              return (i.createdAtDateTime = formattedDate);
            });
            respData.map((i) => {
              const parsedDate = new Date(i.modifiedAtDateTime);
              const year = parsedDate.getFullYear();
              const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
              const day = String(parsedDate.getDate()).padStart(2, "0");
              const formattedDate = `${year}-${month}-${day}`;
              return (i.modifiedAtDateTime = formattedDate);
            });
            this.setState({
              userTableValues: respData,
              showFilterBox: false,
            });
          } else {
            {
              this.toast.show({
                severity: "error",
                summary: "No Records Found For the Filter Values",
                life: 4000,
              });
              this.setState({
                showFilterBox: false,
              });
            }
          }
        });
    }
  }

  reportPaymentStatusBody(rowData) {
    let status;
    if (rowData.paymentEntryNo > 0) {
      status = "Paid";
      return (
        <Tag value={status} severity={status == "Paid" && "success"}></Tag>
      );
    }
  }

  render() {
    const {
      userTableValues,
      sideBarStatus,
      companyName,
      registrationNo,
      postalCode,
      city,
      fromDate,
      toDate,
      reportPaymentStatusBody,
    } = this.state;
    const header = (
      <div className="PrivateUsers-Header-Tab">
        {TranslationFile.SRUUsers[userSelectedLanguage]}
        {/* Annual Reports Users */}
        <span className="p-input-icon-left">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder={TranslationFile.GlobalSearch[userSelectedLanguage]}
          />
        </span>
      </div>
    );

    return (
      <div className="SRU-Main-Div">
        {this.state.showAnnualReportSiteOwner && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="SRU_Sidebar">
              <Sidebar
                children="SRU"
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <Toast
              ref={(el) => {
                this.toast = el;
              }}
              position="center"
              className="SRUdateToast"
            ></Toast>
            <div
              className={sideBarStatus ? "SRU-DataTable" : "SRU-DataTableClose"}
            >
              <div className="SRU-Header-Title"> {header} </div>
              <div>
                <DataTable
                  value={userTableValues}
                  globalFilter={this.state.globalFilter}
                  loading={this.state.loading}
                  emptyMessage={
                    TranslationFile.NoRecordsfound[userSelectedLanguage]
                  }
                  // emptyMessage="No Records found."
                  paginator
                  rows={5}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                >
                  <Column
                    field="reportID"
                    header={TranslationFile.ReportId[userSelectedLanguage]}
                    filter
                    body={this.AnnualReportId}
                  ></Column>
                  <Column
                    field="companyName"
                    header={TranslationFile.CompanyName[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="registrationNo"
                    header={
                      TranslationFile.RegistrationNo[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="postalCode"
                    header={TranslationFile.PostalCode[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="city"
                    header={TranslationFile.City[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="linkSharingLevel"
                    header={
                      TranslationFile.linkSharingLevel[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="accountingCompanySharing"
                    header={
                      TranslationFile.AccountingCompanySharing[
                        userSelectedLanguage
                      ]
                    }
                    filter
                  ></Column>
                  <Column
                    field="createdByUserID"
                    header={
                      TranslationFile.createdByUserID[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="createdAtDateTime"
                    header={TranslationFile.createdAtDate[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="modifiedByUserID"
                    header={
                      TranslationFile.modifiedByUserID[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="modifiedAtDateTime"
                    header={
                      TranslationFile.modifiedAtDate[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="reportStatus"
                    header={TranslationFile.reportStatus[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="reportPaymentStatus"
                    header={
                      TranslationFile.reportPaymentStatus[userSelectedLanguage]
                    }
                    filter
                    body={this.reportPaymentStatusBody}
                  ></Column>
                  <Column
                    field="paymentEntryNo"
                    header={
                      TranslationFile.PaymentEntryNo[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="sendToBolagsverketCount"
                    header={
                      TranslationFile.sendToBolagsverketCount[
                        userSelectedLanguage
                      ]
                    }
                    filter
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              style={{ width: "30rem" }}
              visible={this.state.showFilterBox}
              onHide={() => this.setState({ showFilterBox: false })}
              header={TranslationFile.SRUFilter[userSelectedLanguage]}
            >
              <div>
                <div className="SRUFilterBox_Title_div">
                  <p className="SRUFilter_CompanyName">
                    {TranslationFile.CompanyName[userSelectedLanguage]}
                    {/* Company Name */}
                  </p>
                  <InputText
                    className="SRUFilterBox"
                    value={companyName}
                    onChange={(e) =>
                      this.setState({
                        companyName: e.target.value,
                      })
                    }
                  ></InputText>
                </div>
                <div className="SRUFilterBox_Title_div">
                  <p className="SRUFilterBox_Registration">
                    {TranslationFile.RegistrationNo[userSelectedLanguage]}
                    {/* Registration No */}
                  </p>
                  <InputMask
                    className="SRUFilterBox"
                    value={registrationNo}
                    onChange={(e) =>
                      this.setState({
                        registrationNo: e.target.value,
                      })
                    }
                    mask="999999-9999"
                  ></InputMask>
                </div>
                <div className="SRUFilterBox_Title_div">
                  <p className="SRUFilterBox_Postalcode">
                    {TranslationFile.PostalCode[userSelectedLanguage]}
                    {/* Postal Code */}
                  </p>
                  <InputNumber
                    className="SRUFilterBox"
                    value={postalCode}
                    onChange={(e) =>
                      this.setState({
                        postalCode: e.value,
                      })
                    }
                  ></InputNumber>
                </div>
                <div className="SRUFilterBox_Title_div">
                  <p className="SRUFilterBox_City">
                    {TranslationFile.City[userSelectedLanguage]}
                    {/* City */}
                  </p>
                  <InputText
                    className="SRUFilterBox"
                    value={city}
                    onChange={(e) =>
                      this.setState({
                        city: e.target.value,
                      })
                    }
                  ></InputText>
                </div>
                <div className="SRUFilterBox_Title_div">
                  <p className="SRUFilterBox_createdDate">
                    {TranslationFile.FromDate[userSelectedLanguage]}
                    {/* From Date */}
                  </p>
                  <InputText
                    className="SRUFilterBox"
                    value={fromDate}
                    onChange={(e) =>
                      this.setState({
                        fromDate: e.target.value,
                      })
                    }
                  ></InputText>
                </div>
                <div className="SRUFilterBox_Title_div">
                  <p className="SRUFilterBox_createdDate">
                    {TranslationFile.ToDate[userSelectedLanguage]}
                    {/* To Date */}
                  </p>
                  <InputText
                    className="SRUFilterBox"
                    value={toDate}
                    onChange={(e) =>
                      this.setState({
                        toDate: e.target.value,
                      })
                    }
                  ></InputText>
                </div>
                <br></br>
                <center>
                  <div>
                    <Button
                      onClick={() => {
                        this.filterAnnualReportRecords();
                      }}
                    >
                      {TranslationFile.Submit[userSelectedLanguage]}
                      {/* Submit */}
                    </Button>
                  </div>
                </center>
              </div>
            </Dialog>
            <Dialog
              visible={this.state.showAccessDialogBox}
              onHide={() => this.setState({ showAccessDialogBox: false })}
              header={TranslationFile.ReportAccess[userSelectedLanguage]}
              // header="Report Access"
            >
              <DataTable
                emptyMessage={
                  this.state.headerAccess.length == 0 &&
                  TranslationFile.NoReportfound[userSelectedLanguage]
                }
                value={this.state.headerAccess}
                style={{ width: "50vw" }}
              >
                <Column
                  field="no"
                  header={TranslationFile.No[userSelectedLanguage]}
                ></Column>
                <Column
                  field="userId"
                  header={TranslationFile.UserID[userSelectedLanguage]}
                ></Column>
                <Column
                  field="userName"
                  header={TranslationFile.UserName[userSelectedLanguage]}
                ></Column>
                <Column
                  field="emailID"
                  header={TranslationFile.EmailId[userSelectedLanguage]}
                ></Column>
                <Column
                  field="userAccessLevel"
                  header={TranslationFile.AccessLevel[userSelectedLanguage]}
                ></Column>
              </DataTable>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}
export default SRU;
