import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css"; //icons
import "primeflex/primeflex.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

import SiteOwnerLoginPage from "./Pages/SiteOwnerLoginPage/SiteOwnerLoginPage";
import AccountingCompanySiteOwner from "./Pages/AnnualReportSiteOwnerPages/AccountingCompanySiteOwner/AccountingCompany";
import AnnualReportSiteowner from "./Pages/AnnualReportSiteOwnerPages/AnnualReportSiteOwner/AnnualReport";
import SRU from "./Pages/AnnualReportSiteOwnerPages/SRU/SRU";
import EmailQueueSiteOwner from "./Pages/AnnualReportSiteOwnerPages/EmailQueueSiteOwner/EmailQueue";
import HomePageSiteOwner from "./Pages/AnnualReportSiteOwnerPages/HomePageSiteOwner/HomePage";
import PaymentSiteOwner from "./Pages/AnnualReportSiteOwnerPages/PaymentSiteOwner/Payment";
import PrivateUsersSiteOwner from "./Pages/AnnualReportSiteOwnerPages/PrivateUsersSiteOwner/PrivateUsers";
import TemplateAnnualReportSiteOwner from "./Pages/AnnualReportSiteOwnerPages/TemplateAnnualReportSiteOwner/Template";
import StatusBandAnnualReportSiteOwner from "./Pages/AnnualReportSiteOwnerPages/StatusBandAnnualReportSiteOwner/StatusBand";
import CorporateSOHomePage from "./Pages/CorporateSiteOwnerPages/CorporateSOHomePage/CorporateSOHomePage";
import StatusBandCorporateSO from "./Pages/CorporateSiteOwnerPages/StatusBandCorporateSO/CorporateSOStatusBand";
import Logs from "./Pages/SiteOwnerLogs/Logs/Logs";

let uuid = "";
const history = createBrowserHistory();
let store = createStore(reducers, applyMiddleware(thunk));
const propsStore = store.getState();

export default function App() {
  const [rerender, setRerender] = useState(false);

  let localObject = "";

  let currentPageUrl = window.location.href;
  let splitCurrentPageUrl = currentPageUrl.split("/");

  let currentReportGUID = "";
  if (splitCurrentPageUrl.length == 6) {
    currentReportGUID = splitCurrentPageUrl && splitCurrentPageUrl[4];
  }

  useEffect(() => {
    //Setting default Language for user
    let langFromLS = localStorage.getItem("selectedLanguage");
    if (langFromLS == undefined || langFromLS == "" || langFromLS == null) {
      localStorage.setItem("selectedLanguage", "en");
    }

    //Getting User's Timezone in GMT Format and setting it in Local Storage
    const userDate = new Date();
    const offsetMinutes = userDate.getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetHoursString = String(offsetHours).padStart(2, "0");
    const offsetHoursStringWithoutMinutes = offsetHoursString.split(".")[0];
    const offsetMinutesString = String(Math.abs(offsetMinutes % 60)).padStart(
      2,
      "0"
    );
    const offsetSign = offsetMinutes < 0 ? "+" : "-";
    const userTimezone = `GMT${offsetSign}${offsetHoursStringWithoutMinutes}:${offsetMinutesString}`;
    localStorage.setItem("userTimeZone", userTimezone);

    let navigateToGUID = localStorage.getItem("navigateToGUID");
    axios.get("/cms/generateUUID").then((response) => {
      let currentPageUrl = window.location.href;
      let splitCurrentPageUrl = currentPageUrl.split("/");
      if (splitCurrentPageUrl.length == 7) {
        uuid = splitCurrentPageUrl[5];
      } else {
        uuid = response.data;
      }

      if (currentReportGUID != "") {
        uuid = currentReportGUID;
      }

      if (localObject != "") {
        propsStore["login"]["login"].values = localObject;
      }

      if (navigateToGUID != "" && navigateToGUID != null) {
        uuid = navigateToGUID;
      }
      let sessionDetails = {
        uuid: uuid,
        currentPage: "home",
        IsAnnualReportSubmitted: false,
      };
      propsStore["sessionDetails"]["sessionDetails"].values = sessionDetails;
      localStorage.setItem("sessionUUID", uuid);
      setRerender(true);
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={SiteOwnerLoginPage} />
          <Route exact path="/siteOwner" component={SiteOwnerLoginPage} />
          <Route
            exact
            path="/siteOwner/appsPortal"
            component={HomePageSiteOwner}
          />
          <Route exact path="/siteOwner/logs" component={Logs} />
          <Route
            exact
            path="/siteOwner/appsPortal/template"
            component={TemplateAnnualReportSiteOwner}
          />
          <Route
            exact
            path="/siteOwner/appsPortal/emailQueue"
            component={EmailQueueSiteOwner}
          />
          <Route
            exact
            path="/siteOwner/appsPortal/annualReport"
            component={AnnualReportSiteowner}
          />
          <Route exact path="/siteOwner/appsPortal/SRU" component={SRU} />
          <Route
            exact
            path="/siteOwner/appsPortal/PrivateUsers"
            component={PrivateUsersSiteOwner}
          />
          <Route
            exact
            path="/siteOwner/appsPortal/StatusBand"
            component={StatusBandAnnualReportSiteOwner}
          />
          <Route
            exact
            path="/siteOwner/appsPortal/payment"
            component={PaymentSiteOwner}
          />
          <Route
            exact
            path="/siteOwner/appsPortal/accountingCompany"
            component={AccountingCompanySiteOwner}
          />
          <Route
            exact
            path="/siteOwner/corporatePortal/CorporateHomePage"
            component={CorporateSOHomePage}
          />
          <Route
            exact
            path="/siteOwner/corporatePortal/StatusBand"
            component={StatusBandCorporateSO}
          />
        </Switch>
      </Router>
    </Provider>
  );
}
