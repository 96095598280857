import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import "../PrivateUsersSiteOwner/PrivateUsers.css";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import TranslationFile from "../../Translation/Translation.json";

let userSelectedLanguage;
let loginDetails = "";

class PrivateUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsDialogVisible: false,
      userTableValues: "",
      modifiedformatdatetime: "",
      globalFilter: null,
      loading: false,
      sideBarStatus: true,
      showPrivateUsers: false,
    };
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showPrivateUsers: true });

      axios
        .get("/siteOwner/getAnnualReportUsersForSiteOwner")
        .then((response) => {
          let respData = response.data.privateUsersList;
          respData.map((res) => {
            if (res.mobileNo == "0") return (res.mobileNo = "-");
          });
          respData.map((i) => {
            let date = i.createdAtDateTime.split("T")[0];
            return (i.createdAtDateTime = date);
          });
          this.setState({
            userTableValues: respData,
          });
        });
    } else {
      this.setState({ showPrivateUsers: false });
      this.props.history.push("/siteOwner");
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  render() {
    const { userTableValues, sideBarStatus } = this.state;
    const header = (
      <div className="PrivateUsers-Header-Tab">
        {TranslationFile.PrivateUsers[userSelectedLanguage]}
        {/* Private Users */}
        <span className="p-input-icon-left">
          {/* <i className="pi pi-search" /> */}
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder={TranslationFile.GlobalSearch[userSelectedLanguage]}
          />
        </span>
      </div>
    );

    return (
      <div className="PrivateUsers-Main-Div">
        {this.state.showPrivateUsers && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="PrivateUsers_Sidebar">
              <Sidebar
                children={"PrivateUsers"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <div
              className={
                sideBarStatus
                  ? "PrivateUsers-DataTable"
                  : "PrivateUsers-DataTableClose"
              }
              // className="PrivateUsers-DataTable"
            >
              <div className="PrivateUsers-Header-Title"> {header} </div>
              <div>
                <DataTable
                  value={userTableValues}
                  globalFilter={this.state.globalFilter}
                  loading={this.state.loading}
                  emptyMessage="No Records found."
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                >
                  <Column
                    field="userID"
                    header={TranslationFile.UserID[userSelectedLanguage]}
                    filter
                    className="SoPrivate_user"
                  ></Column>
                  <Column
                    field="userName"
                    header={TranslationFile.UserName[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="emailID"
                    header={TranslationFile.Email[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="mobileNo"
                    header={TranslationFile.MobileNo[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="isEmailVerified"
                    header={
                      TranslationFile.IsEmailVerified[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="createdAtDateTime"
                    header={
                      TranslationFile.CreatedDateTime2[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.reportsDialogVisible}
              onHide={() => this.setState({ reportsDialogVisible: false })}
              header={TranslationFile.UserReportList[userSelectedLanguage]}
            ></Dialog>
          </div>
        )}
      </div>
    );
  }
}
export default PrivateUsers;
