import React, { useEffect, useState, useRef } from "react";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar";
import SOLogsSideBar from "../SOLogsSideBar/SOLogsSideBar";
import "./Logs.css";
import { Button } from "primereact/button";
import TranslationFile from "../../Translation/Translation.json";
import { Breadcrumb } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import {
  faFileImage,
  faFileAlt,
  faFileAudio,
  faFileVideo,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import pako from "pako";
import Config from "../../../Configuration/Config";

let breadCrumbArray = [{ name: "Home", type: "" }],
  month = "",
  year = "2024";
const Logs = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [currentTraverse, setCurrentTraverse] = useState([]);
  const [breadCrumbList, setBreadCrumbList] = useState("");
  const [content, setContent] = useState("");
  const [currentFileName, setCurrentFileName] = useState("");
  const [showDocDialog, setShowDocDialog] = useState(false);
  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [showLogHomePage, setShowLogHomePage] = useState(false);
  const [lines, setLines] = useState([]);
  const [visibleLines, setVisibleLines] = useState([]);
  const [startLine, setStartLine] = useState(0);
  const containerRef = useRef(null);
  const linesPerLoad = 30;
  useEffect(() => {
    let userSelectedLanguage = localStorage.getItem("selectedLanguage");
    let loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      setShowLogHomePage(true);
    } else {
      setShowLogHomePage(false);
      history.push("/siteOwner");
    }
    callLogAPIWithoutYearAndMonth();
  }, []);

  const callLogAPIWithoutYearAndMonth = () => {
    axios.get("/siteOwner/logTraverse").then((response) => {
      if (response.data.length > 0) {
        let respData = response.data;
        respData.forEach((val, idx) => {
          if (val.includes(".")) {
            respData[idx] = { type: "file", name: val };
          } else {
            respData[idx] = { type: "folder", name: val };
          }
        });
        setCurrentTraverse(respData);
      }
    });
  };
  const callLogTraverseAPI = (data, idx, from) => {
    month = "";
    if (from == "breadCrumb") {
      const updatedButtons = breadCrumbArray.slice(0, idx + 1);
      breadCrumbArray = updatedButtons;
    }
    let splittedDataName = data.name.split(".").includes("log");
    if (!breadCrumbArray.includes(data) && !splittedDataName) {
      breadCrumbArray.push(data);
    }

    if (breadCrumbArray.length == 1 && breadCrumbArray[0].name == "Home") {
      callLogAPIWithoutYearAndMonth();
    }
    if (data.type == "folder") {
      if (data.name == "archived") {
        let respData = [{ type: "folder", name: "2024" }];
        setCurrentTraverse(respData);
      } else {
        // call the API again to go inside the archieve folder

        if (!isNaN(Number(data.name))) {
          if (data.name.length == 4) {
          } else if (data.name.length == 2) {
            month = data.name;
          }
        }

        axios
          .get("/siteOwner/logTraverse?year=" + year + "&month=" + month)
          .then((response) => {
            if (response.data.length > 0) {
              let respData = response.data;
              respData.forEach((val, idx) => {
                if (val.includes(".")) {
                  respData[idx] = { type: "file", name: val };
                } else {
                  respData[idx] = { type: "folder", name: val };
                }
              });

              setCurrentTraverse(respData);
            }
          });
      }
    } else {
      //call the get file API and decompress it and show the content of the file

      let selectedYear = data.name;
      selectedYear = selectedYear.split(".");
      if (selectedYear[selectedYear.length - 1] == "gz") {
        let fileYear = selectedYear[1].split("-");
        month = fileYear[1];
        year = fileYear[0];
      }
      openLogFile(data);
    }
  };

  const openLogFile = (data) => {
    let linkUrl = "";
    if (month != "" && year != "") {
      linkUrl =
        Config.backend.url +
        "siteOwner/logTest?fileName=" +
        data.name +
        "&&year=" +
        year +
        "&&month=" +
        month;
    } else {
      linkUrl = Config.backend.url + "siteOwner/logTest?fileName=" + data.name;
    }
    if (linkUrl != "") {
      fetch(linkUrl, {
        method: "GET",
      })
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          // Decompress the gzipped content
          const decompressed = pako.ungzip(new Uint8Array(buffer), {
            to: "string",
          });
          const allLines = decompressed.split("\n");
          setLines(allLines);
          const initialStartLine = Math.max(0, allLines.length - linesPerLoad);
          setStartLine(initialStartLine);
          setVisibleLines(allLines.slice(initialStartLine, allLines.length));
          setContent(decompressed);
          setShowDocDialog(true);
          setCurrentFileName(data.name);
        })
        .catch((error) => {});
    }
  };
  const handleScroll = () => {
    if (containerRef.current.scrollTop === 0 && startLine > 0) {
      scrollUp();
    }
  };

  const scrollUp = () => {
    const newStartLine = Math.max(0, startLine - linesPerLoad);
    const newVisibleLines = lines
      .slice(newStartLine, startLine)
      .concat(visibleLines);

    setStartLine(newStartLine);
    setVisibleLines(newVisibleLines),
      () => {
        // Set the scroll position to roughly the middle to prevent it from jumping back to the previous position
        if (containerRef.current) {
          const previousScrollHeight = containerRef.current.scrollHeight;
          requestAnimationFrame(() => {
            containerRef.current.scrollTop =
              containerRef.current.scrollHeight - previousScrollHeight;
          });
        }
      };
  };
  const downloadFile = () => {
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = currentFileName + ".log";
    link.click();
  };

  const sideBarStatusFunc = (e) => {
    setSideBarStatus(e);
  };

  return (
    <div className="SOLog_homepage">
      {showLogHomePage && (
        <div>
          <div>
            <HeaderNavBar />
          </div>
          <div className="SOLogsSideBar_sideBar">
            <SOLogsSideBar
              children={"HomePage"}
              sideBarStatus={sideBarStatusFunc}
            />
          </div>
          <div
            className={
              sideBarStatus ? "so-main-div-Log" : "so-main-div-LogClose"
            }
          >
            <Col
              md={12}
              className={
                "d-flex align-items-center px-5 pt-3 justify-content-between"
              }
            >
              <Breadcrumb>
                {breadCrumbArray &&
                  breadCrumbArray.map((val, idx) => {
                    return (
                      <Breadcrumb.Item
                        onClick={() =>
                          callLogTraverseAPI(val, idx, "breadCrumb")
                        }
                      >
                        {val.name}
                      </Breadcrumb.Item>
                    );
                  })}
              </Breadcrumb>
            </Col>

            <Row style={{ height: "150px" }} className="pt-2 pb-4 px-5">
              {currentTraverse &&
                currentTraverse.map((data, idx) => (
                  <Col
                    onDoubleClick={() => {
                      callLogTraverseAPI(data, idx, "main");
                      // if (data.type == "file") {
                      //   openLogFile(data);
                      // }
                    }}
                    onClick={(e) => {
                      if (e.currentTarget.classList.contains("text-white")) {
                        e.currentTarget.style.background = "#fff";
                        e.currentTarget.classList.remove("text-white");
                        e.currentTarget.classList.remove("shadow-sm");
                      } else {
                        e.currentTarget.style.background = "#017bf562";
                        e.currentTarget.classList.add("text-white");
                        e.currentTarget.classList.add("shadow-sm");
                      }
                    }}
                    md={2}
                    style={{ margin: "2% 2%", border: "1px solid red" }}
                    className="border h-150  d-flex align-items-center justify-content-around flex-column py-1 rounded-2"
                  >
                    <FontAwesomeIcon
                      icon={
                        data.name.split(".").includes("log")
                          ? faFileAlt
                          : faFolder
                      }
                      className="mt-3"
                      style={{ fontSize: "3rem" }}
                    />
                    <p className="text-center mt-3">{data.name}</p>
                  </Col>
                ))}
            </Row>
          </div>
          <Dialog
            visible={showDocDialog}
            onHide={() => setShowDocDialog(false)}
            style={{
              height: "90%",
              width: "90%",
            }}
            // blockScroll
            className="show-doc-dialog"
          >
            <div>
              <Button
                className="SO_scroll_Up_Btn"
                onClick={scrollUp}
                icon="pi pi-arrow-up"
                label="Scroll Up"
              />

              <Button
                className="SO_download_Btn"
                icon="pi pi-download"
                label="Download"
                onClick={downloadFile}
                style={{ float: "right" }}
              />

              <div
                ref={containerRef}
                onScroll={handleScroll}
                style={{
                  height: "600px",
                  overflowY: "scroll",
                  border: "1px solid grey",
                  // boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  marginTop: "1%",
                }}
              >
                {visibleLines &&
                  visibleLines.map((line, index) => (
                    <div key={index}>
                      {startLine + index + 1}: {line}
                    </div>
                  ))}
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Logs;
