import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css"; //icons
import "primeflex/primeflex.css";
import axios from "axios";
import Config from "./Configuration/Config";

// axios.defaults.baseURL = "https://testapi.rebelskool.com/";
// axios.defaults.baseURL = "http://localhost:8080";
axios.defaults.baseURL = Config.backend.url;
let statusBar = false;

ReactDOM.render(
  <React.StrictMode>
    <div className="app">
      <App />
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);
