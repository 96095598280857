import React, { useState, useEffect } from "react";
import TranslationFile from "../../Translation/Translation.json";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FcOpenedFolder, FcAutomatic } from "react-icons/fc";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import "../SOLogsSideBar/SOLogsSideBar.css";

let userSelectedLanguage;

const SOLogsSideBar = ({ children, sideBarStatus, switchValue }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedSideMenu, setSelectedSideMenu] = useState("");
  const activeSideBarReducer = useSelector(
    (state) => state.activeSideBar.activeSideBar.values
  );
  userSelectedLanguage = localStorage.getItem("selectedLanguage");

  const SiteOwnerLogHomePage = "/siteOwner/logs";

  const routes = [
    {
      swName: "Min instrumentpanel",
      name: "Home Page",
      icon: <FcOpenedFolder className="icon" />,
      path: SiteOwnerLogHomePage,
    },
  ];

  useEffect(() => {
    if (activeSideBarReducer != undefined)
      setSelectedSideMenu(activeSideBarReducer[0]);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
    sideBarStatus(!isOpen);
  };

  return (
    <div>
      <div
        className={
          isOpen
            ? "main-container-sidebarOpenClass"
            : "main-container-sidebarCloseClass"
        }
      >
        <motion.div
          animate={{
            width: isOpen ? "250px" : "50px",
          }}
          className={switchValue ? "sidebarExtent" : `sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <p className="subtext-annual-report">
                  {/* SiteOwner Logs */}
                  {TranslationFile.SiteOwnerLogs[userSelectedLanguage]}
                </p>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen == true ? (
                <RxDoubleArrowLeft className="icon" onClick={toggle} />
              ) : (
                <RxDoubleArrowRight className="icon" onClick={toggle} />
              )}
            </div>
          </div>

          <section className="routes">
            {/* Home Page */}
            <NavLink
              to={routes[0].path}
              className={children == "HomePage" ? "sidebarActive" : "notesLink"}
              activeClassName="active"
            >
              <div className="icon">{routes[0].icon}</div>
              <div className="menu-names-link">
                {isOpen && (
                  <div>
                    {userSelectedLanguage == "sw"
                      ? routes[0].swName
                      : routes[0].name}
                  </div>
                )}
              </div>
            </NavLink>
          </section>
        </motion.div>
      </div>
    </div>
  );
};
export default SOLogsSideBar;
