import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../StatusBandAnnualReportSiteOwner/StatusBand.css";
import { Toast } from "primereact/toast";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import TranslationFile from "../../Translation/Translation.json";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let loginDetails = "";
let userSelectedLanguage;

class StatusBand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      checked1: "false",
      selectedStatusApps: "",
      appsEn: "",
      appsSw: "",
      sideBarStatus: true,
      appsEnValueOnChange: false,
      statusColor: "",
      showAppsStatusBand: false,
    };
    this.options = ["Off", "On"];

    this.statusType = [
      { name: "Success", code: "S", color: "green" },
      { name: "Info", code: "I", color: "blue" },
      { name: "Error", code: "E", color: "red" },
    ];

    this.selectStatus = this.selectStatus.bind(this);
    this.UpdateStatusBand = this.UpdateStatusBand.bind(this);
  }

  selectStatus(e) {
    this.setState({ selectedStatusApps: e.target.value });
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showAppsStatusBand: true });

      axios.get("/siteOwner/getStatusBand?appId=1").then((response) => {
        let respData = response.data;
        this.setState({
          checked1: respData.activate,
          appsEn: respData.statusTextEn,
          appsSw: respData.statusTextSw,
          statusColor: respData.statusColor,
        });

        if (respData.statusType == 0) {
          this.setState({
            selectedStatusApps: this.statusType[0],
          });
        } else if (respData.statusType == 1) {
          this.setState({
            selectedStatusApps: this.statusType[1],
          });
        } else {
          this.setState({
            selectedStatusApps: this.statusType[2],
          });
        }
      });
    } else {
      this.setState({ showAppsStatusBand: false });
      this.props.history.push("/siteOwner");
    }
  }

  onChangeFunction(e) {
    this.setState({ activeIndex: e.index });
    let appID = e.index + 1;

    axios.get("/siteOwner/getStatusBand?appId=" + appID).then((response) => {
      let respData = response.data;
      console.log(respData, "@@33");
      this.setState({
        checked1: respData.activate,
        appsEn: respData.statusTextEn,
        appsSw: respData.statusTextSw,
        statusColor: respData.statusColor,
      });

      if (respData.statusType == 0) {
        this.setState({
          selectedStatusApps: this.statusType[0],
        });
      } else if (respData.statusType == 1) {
        this.setState({
          selectedStatusApps: this.statusType[1],
        });
      } else {
        this.setState({
          selectedStatusApps: this.statusType[2],
        });
      }
    });
  }

  UpdateStatusBand(portal) {
    let { selectedStatusApps } = this.state;
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      let portalUpdate = "",
        statusType;
      if (portal == "apps") {
        if (selectedStatusApps.name == "Success") {
          statusType = 0;
        } else if (selectedStatusApps.name == "Info") {
          statusType = 1;
        } else statusType = 2;

        console.log(this.state.activeIndex, "##44");
        portalUpdate = {
          appId: this.state.activeIndex + 1,
          statusTextEn: this.state.appsEn,
          statusTextSw: this.state.appsSw,
          statusType: statusType,
          activate: this.state.checked1,
          statusColor: "#" + this.state.statusColor,
        };
      }

      axios
        .post(
          "/siteOwner/updateSiteOwnerStatusBand",
          portalUpdate,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data == "Success") {
            this.toast.show({
              severity: "success",
              summary: "success",
              life: 3000,
            });
          }
          this.setState({ appsEnValueOnChange: false });
        });
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  checked1OnSwitch(e) {
    this.setState({ checked1: e.value, appsEnValueOnChange: true });
  }
  appsEnOnChange(e) {
    this.setState({ appsEn: e.target.value, appsEnValueOnChange: true });
  }
  appsSwOnChange(e) {
    this.setState({ appsSw: e.target.value, appsEnValueOnChange: true });
  }

  appsStatusType(e) {
    this.setState({
      selectedStatusApps: e.target.value,
      appsEnValueOnChange: true,
    });
  }

  render() {
    const { selectedStatusApps, sideBarStatus, statusColor } = this.state;
    const header = (
      <div className="statusBand-Header-Tab">
        {TranslationFile.Statusband[userSelectedLanguage]}
        {/* Status Band */}
      </div>
    );

    return (
      <div className="StatusBand-Main-Div">
        {this.state.showAppsStatusBand && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="StatusBand_Sidebar">
              <Sidebar
                children={"StatusBand"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <Toast
              ref={(el) => {
                this.toast = el;
              }}
              position="center"
            // className="wrongSIEFileUpload"
            ></Toast>

            <div
              className={
                sideBarStatus
                  ? "StatusBand-DataTable"
                  : "StatusBand-DataTableClose"
              }
            >
              <div className="statusBand-Header-Title"> {header} </div>
              <TabView
                className="StatusBand-set-up-tab"
                activeIndex={this.state.activeIndex}
                // onTabChange={(e) => this.setState({ activeIndex: e.index })}
                onTabChange={(e) => this.onChangeFunction(e)}
              >
                <TabPanel header="Annual Report Status Band">
                  <div className="statusAlert-Main-Div">
                    <div>
                      <Row style={{ padding: "5px 20px" }}>
                        <Col xs={5}>
                          <b className="StatusBand_table1">
                            {TranslationFile.Activate[userSelectedLanguage]}
                            {/* Activate */}
                          </b>
                          <InputSwitch
                            checked={this.state.checked1}
                            onChange={(e) => this.checked1OnSwitch(e)}
                          />
                          <br></br>
                          <br></br>
                          <Row>
                            <Col xs={5}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusTextEnglish[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                              {/* <b>Status Text English</b> */}
                              <InputTextarea
                                autoResize
                                value={this.state.appsEn}
                                onChange={(e) => this.appsEnOnChange(e)}
                                rows={2}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={5}>
                              <b>
                                {
                                  TranslationFile.StatusTextSwedish[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                              {/* <b>Status Text Swedish</b> */}
                              <InputTextarea
                                autoResize
                                value={this.state.appsSw}
                                onChange={(e) => this.appsSwOnChange(e)}
                                rows={2}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "15px" }}>
                            <Col xs={4}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusType[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                              {/* <b>Status Type</b> */}
                              <Dropdown
                                value={selectedStatusApps}
                                onChange={(e) => this.appsStatusType(e)}
                                options={this.statusType}
                                optionLabel="name"
                                placeholder="select...."
                                className="appStatusdropdown"
                              // itemTemplate={this.colorOptionTemplate}
                              // valueTemplate={this.selectedColorTemplate}
                              />
                            </Col>
                            <Col xs={5}>
                              <b>
                                {
                                  TranslationFile.StatusColor[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                              {/* <b>Status Color</b> */}
                              <br></br>
                              <ColorPicker
                                value={this.state.statusColor}
                                onChange={(e) =>
                                  this.setState({
                                    statusColor: e.value,
                                    appsEnValueOnChange: true,
                                  })
                                }
                              />
                              <InputText
                                value={this.state.statusColor}
                                onChange={(e) =>
                                  this.setState({
                                    statusColor: e.target.value,
                                    appsEnValueOnChange: true,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col>
                              <Button
                                onClick={() => this.UpdateStatusBand("apps")}
                                disabled={
                                  !this.state.appsEnValueOnChange && true
                                }
                              >
                                {TranslationFile.SAVE[userSelectedLanguage]}
                                {/* SAVE */}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPanel>
                {/* <TabPanel header="SRU Status Band">
                  <div className="statusAlert-Main-Div">
                    <div>
                      <Row style={{ padding: "5px 20px" }}>
                        <Col xs={5}>
                          <b className="StatusBand_table1">
                            {TranslationFile.Activate[userSelectedLanguage]}
                      
                          </b>
                          <InputSwitch
                            checked={this.state.checked1}
                            onChange={(e) => this.checked1OnSwitch(e)}
                          />
                          <br></br>
                          <br></br>
                          <Row>
                            <Col xs={5}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusTextEnglish[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                              
                              <InputTextarea
                                autoResize
                                value={this.state.appsEn}
                                onChange={(e) => this.appsEnOnChange(e)}
                                rows={2}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={5}>
                              <b>
                                {
                                  TranslationFile.StatusTextSwedish[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                           
                              <InputTextarea
                                autoResize
                                value={this.state.appsSw}
                                onChange={(e) => this.appsSwOnChange(e)}
                                rows={2}
                                cols={40}
                              />
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "15px" }}>
                            <Col xs={4}>
                              <b>
                                {" "}
                                {
                                  TranslationFile.StatusType[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                           
                              <Dropdown
                                value={selectedStatusApps}
                                onChange={(e) => this.appsStatusType(e)}
                                options={this.statusType}
                                optionLabel="name"
                                placeholder="select...."
                                className="appStatusdropdown"
    
                              />
                            </Col>
                            <Col xs={5}>
                              <b>
                                {
                                  TranslationFile.StatusColor[
                                  userSelectedLanguage
                                  ]
                                }
                              </b>
                   
                              <br></br>
                              <ColorPicker
                                value={this.state.statusColor}
                                onChange={(e) =>
                                  this.setState({
                                    statusColor: e.value,
                                    appsEnValueOnChange: true,
                                  })
                                }
                              />
                              <InputText
                                value={this.state.statusColor}
                                onChange={(e) =>
                                  this.setState({
                                    statusColor: e.target.value,
                                    appsEnValueOnChange: true,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col>
                              <Button
                                onClick={() => this.UpdateStatusBand("apps")}
                                disabled={
                                  !this.state.appsEnValueOnChange && true
                                }
                              >
                                {TranslationFile.SAVE[userSelectedLanguage]}
                            
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPanel> */}
              </TabView>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default StatusBand;
