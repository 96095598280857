import React, { Component } from "react";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import "../AccountingCompanySiteOwner/AccountingCompany.css";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import TranslationFile from "../../Translation/Translation.json";

let userSelectedLanguage;
let loginDetails = "";
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
class AccountingCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsDialogVisible: false,
      userReportDetailsTable: "",
      userTableValuesForAcc: "",
      globalFilter: null,
      loading: false,
      sideBarStatus: true,
      showAccountingCompanySiteOwner: false,
    };

    this.getAccountingCompanyUserID =
      this.getAccountingCompanyUserID.bind(this);
    this.AccountingCompanyUserId = this.AccountingCompanyUserId.bind(this);
  }

  getAccountingCompanyUserID(accountingCompanyID) {
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/siteOwner/getAccountingUsersByAccountingCompanyID?accountingCompanyId=" +
            accountingCompanyID,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let respData = response.data;
          respData.map((res) => {
            if (res.active) {
              res.active = "Active";
            } else {
              res.active = "InActive";
            }
            return res;
          });
          respData.map((res) => {
            if (res.createdByUserName == null) {
              res.createdByUserName = "Admin";
            }
            return res;
          });

          // if (respData.length > 0) {
          this.setState({
            userReportDetailsTable: respData,
            reportsDialogVisible: true,
          });
          // }
        });
    }
  }

  AccountingCompanyUserId(rowData) {
    return (
      <div
        style={{
          textDecoration: "underline",
          color: "blue",
          cursor: "pointer",
        }}
        onClick={() =>
          this.getAccountingCompanyUserID(rowData.accountingCompanyID)
        }
      >
        {rowData.accountingCompanyID}
      </div>
    );
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showAccountingCompanySiteOwner: true });

      userSessionAuthKeyReturnValue = setUserSessionAuthKey();
      if (userSessionAuthKeyReturnValue) {
        axios
          .get(
            "/siteOwner/getAccoutingCompanyForSiteOwner",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respDataAcc = response.data;
            respDataAcc.map((res) => {});
            this.setState({
              userTableValuesForAcc: respDataAcc,
            });
          });
      }
    } else {
      this.setState({ showAccountingCompanySiteOwner: false });
      this.props.history.push("/siteOwner");
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  render() {
    const { sideBarStatus } = this.state;
    const header = (
      <div className="AccountingCompany-Header-Tab">
        {TranslationFile.AccountingCompany[userSelectedLanguage]}
        {/* Accounting Company */}
        <span className="p-input-icon-left">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder={TranslationFile.GlobalSearch[userSelectedLanguage]}
          />
        </span>
      </div>
    );
    return (
      <div className="AccountingCompany-Main-Div">
        {this.state.showAccountingCompanySiteOwner && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="AccountingCompany_Sidebar">
              <Sidebar
                children={"AccountingCompany"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <div
              className={
                sideBarStatus
                  ? "AccountingCompany-DataTable"
                  : "AccountingCompany-DataTableClose"
              }
              // className="AccountingCompany-DataTable"
            >
              <div className="AccountingCompany-Header-Title"> {header} </div>

              <div header="Accounting Company Users">
                <DataTable
                  value={this.state.userTableValuesForAcc}
                  globalFilter={this.state.globalFilter}
                  loading={this.state.loading}
                  emptyMessage={
                    TranslationFile.NoRecordsfound[userSelectedLanguage]
                  }
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                >
                  <Column
                    field="accountingCompanyID"
                    header={TranslationFile.ACiD[userSelectedLanguage]}
                    filter
                    body={this.AccountingCompanyUserId}
                  ></Column>
                  <Column
                    field="accountingCompanyName"
                    header={TranslationFile.ACName[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="registrationNo"
                    header={
                      TranslationFile.RegistrationNo[userSelectedLanguage]
                    }
                    filter
                  ></Column>
                  <Column
                    field="address"
                    header={TranslationFile.Address[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="postalCode"
                    header={TranslationFile.PostalCode[userSelectedLanguage]}
                    filter
                  ></Column>
                  <Column
                    field="city"
                    header={TranslationFile.City[userSelectedLanguage]}
                    filter
                  ></Column>
                </DataTable>
              </div>
            </div>
            <Dialog
              visible={this.state.reportsDialogVisible}
              onHide={() => this.setState({ reportsDialogVisible: false })}
              header={TranslationFile.UserReportList[userSelectedLanguage]}
            >
              <DataTable
                emptyMessage={
                  this.state.userReportDetailsTable.length == 0 &&
                  TranslationFile.NoUserfound[userSelectedLanguage]
                }
                value={this.state.userReportDetailsTable}
                style={{ width: "50vw" }}
              >
                <Column
                  field="userName"
                  header={TranslationFile.UserName[userSelectedLanguage]}
                ></Column>
                <Column
                  field="emailID"
                  header={TranslationFile.EmailId[userSelectedLanguage]}
                ></Column>
                <Column
                  field="mobileNo"
                  header={TranslationFile.MobileNo[userSelectedLanguage]}
                ></Column>
                <Column
                  field="accountingCompanyUserRole"
                  header={TranslationFile.userRole[userSelectedLanguage]}
                ></Column>
                <Column
                  field="createdByUserName"
                  header={
                    TranslationFile.createdByUserName[userSelectedLanguage]
                  }
                ></Column>
                <Column
                  field="active"
                  header={TranslationFile.Status[userSelectedLanguage]}
                ></Column>
              </DataTable>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}
export default AccountingCompany;
